@import '../../../../../styles/responsiveText';
@import '../../../../../styles/variables';

.valueProp {
    color: var(--text-color);
    display: flex;
    column-gap: 1rem;
    .iconContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon {
            background-color: var(--color-six);
            border-radius: 50%;

            display: flex;
            flex-direction: column;
            justify-content: center;

            width: 32px;
            height: 32px;

            svg {
                margin: 0 auto;
            }

            @media (max-width: $mobile-mode-breakpoint-logged-out) {
                width: 24px;
                height: 24px;
                top: -20px;
                left: -20px;

                svg {
                    scale: 75%;
                }
            }

            @media (max-width: $small-screen-max-width) {
                width: 22px;
                height: 22px;
                top: -18px;
                left: -18px;

                svg {
                    scale: 60%;
                }
            }
        }
    }

    .title {
        @include responsive-text-small;
        font-weight: $default-bold-weight;
    }

    .subtitle {
        @include responsive-text-extra-small;
        margin-top: 0.5rem;
    }
}
