@import '../../../../../styles/responsiveText';
@import '../../../../../styles/variables';
@import '../../../../../styles/media';

.text {
    color: var(--text-color);
    margin: 2rem;
    @include respond-to(sm) {
        margin-top: 0;
    }

    .titleText {
        @include responsive-text-large;
        font-weight: $default-bold-weight;
        @include respond-to(xs) {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }

        .emphasis {
            color: var(--color-five);
        }
    }

    .subtitleText {
        @include responsive-text-small;
        line-height: $line-height;
        @include respond-to(xs) {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }
    }
}
