@import '../../../../styles/gradient';
@import '../../../../styles/media';
@import '../../../../styles/limitMaxWidth.module';

.splashPageTopSectionContainer {
    width: 100%;
    background: var(--splash-page-background-gradient);
    height: 100%;
    padding: 1rem 0 1.5rem 0;
    @include respond-to(sm) {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }

    .splashPageTopSection {
        position: relative;
        z-index: 1;
        margin-left: 2rem;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        column-gap: 2rem;
        opacity: 100%;
        @include limit-max-width;
        @include respond-to(xs) {
            flex-direction: column;
        }

        .splashPageTopSectionContent {
            @include respond-to(sm) {
                margin-left: 0;
            }

            .splashPageValuePropContainer {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                @include respond-to(xs) {
                    margin: auto 1.5rem;
                }
            }
        }
    }

    .splashPageCTAButtonExternalContainer {
        max-width: 600px;
        margin-top: 2rem;

        .splashPageSmallTextContainer {
            display: flex;
            justify-content: center;

            @include respond-to(xs) {
                margin-top: 1rem;
            }
        }
    }
}

.landingImage {
    height: 25vh;
    width: auto;
    border-radius: 50%;

    @include respond-to(xs) {
        height: 20vh;
        width: auto;
        margin: 0 auto;
    }
}

.pharmacyTopCTAButtonContainer {
    @include respond-to(xs) {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        max-width: 80vw;
        margin: 0 auto;
    }
}
